<template>
  <div class="main-wrapper">
    <Navigation></Navigation>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from './components/navigation.vue';
import Footer from './components/footer.vue';

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  }
}
</script>

<style>
body{
  font-family: 'Manrope', sans-serif;
}

</style>
