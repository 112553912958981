import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'swiper/css';



// import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.css'
// import bootstrap from 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap'

createApp(App).use(router).mount('#app')
