<template>
  <section class="responseDataWrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="easypesa-content">
            <h3>Easy Paisa</h3>
            <p class="mb-2">
              <strong>Note:</strong> For Talent Test Form Fee you need to
              deposit Rs. 500/= Five hundered rupees through easy paisa account.
            </p>
            <p class="mb-0"><strong>Easypaisa Account</strong>: 03339119145</p>
            <p class="mb-0"><strong>ACCOUNT TITLE</strong>: Tajamul Hayat</p>
          </div>
        </div>
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="easypesa-content">
            <h3>Bank Account</h3>
            <p class="mb-2">
              <strong>Account #</strong> Ammad Zarif Babar MCB Town Branch
              Peshawar
            </p>
            <p><strong>0733455141004174</strong></p>
          </div>
        </div>
        <div class="col-lg-12 mt-5">
          <div>
            <div class="alert alert-success text-center" role="alert">
              For Talent Test Form Fee you need to deposit Rs. 500/= Against
              this Voucher Code : <strong>{{ dataFromFirstComponent }}</strong>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
            <div class="text-center linkback">
                <router-link :to="{ name: 'home' }">Go Back To Home</router-link>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "contact-view",

  data() {
    return {
      dataFromFirstComponent: null,
    };
  },
  created() {
    this.dataFromFirstComponent = this.$route.query.data;
  },
};
</script>


<style>
.responseDataWrapper{
    padding: 100px 0px;
}
.linkback a{
    padding: 14px 14px;
    border: none;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 0px;
    font-weight: 700;
    background: #003e78;
    color: #fff;
    transition: 0.3s ease-in-out;
    margin-top: 14px;
}
.linkback a:hover {
    border-radius: 5px;
    color: #fff;
    box-shadow: 0px 1px 22px -2px rgb(0 62 120 / 48%);
}

</style>